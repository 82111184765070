import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Reorder, { reorder } from 'react-reorder';

import {
  AppTableRow,
  AppTableCell,
} from '../../../../../../components/AppTableComponents';

import ItemRow from './ItemRow';

const ItemsTableBody = ({ useStyle }) => {
  const { values, setFieldValue } = useFormikContext();

  const { items } = values;

  function onReorder(event, previousIndex, nextIndex) {
    setFieldValue('items', reorder([...items], previousIndex, nextIndex));
  }

  return (
    <Reorder
      reorderId="itemListComponent"
      component="tbody"
      lock="horizontal"
      onReorder={onReorder}
      autoScroll={true}
      disabled={true}
      holdTime={300}
      touchHoldTime={300}
      mouseHoldTime={300}
      disableContextMenus={true}
      style={useStyle}
      placeholder={
        <AppTableRow>
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
        </AppTableRow>
      }
    >
      {items.map((item, index) => (
        <ItemRow key={index} index={index} item={item} />
      ))}
    </Reorder>
  );
};

ItemsTableBody.propTypes = {
  useStyle: PropTypes.object,
};

export default ItemsTableBody;
