import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { map, isObject, find } from 'lodash';

import { Grid } from '@material-ui/core';

import { PHONE_NUMBER_TEXT_REGEX } from 'constants/form';
import ClientExtraAddress from 'containers/Invoice/Form/Information/ClientInfo/ClientExtraAddress';
import TextField from '../../../components/FormElements/textField';
import AppCheckbox from '../../../components/AppCheckbox';
import AppButton from '../../../components/AppButton';
import AppRadioGroup from '../../../components/AppRadioGroup';
import AppRadioButton from '../../../components/AppRadioButton';
import AppCompanySearch from '../../../components/AppCompany/AppCompanySearch';
import SelectAutoComplete from '../../../components/SelectAutocomplete';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';
import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';
import capitalizeForm from '../../../helpers/capitalizeForm';

const countryList = require('../../../assets/data/countryList.json');

class ClientsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siret: props.values.siret,
      clientFilter: '',
      vat: !!props.values.intracom_vat,
      hasError: '',
      clientExtraFieldnames: {
        address: 'adresses[0].address',
        zipcode: 'adresses[0].zipcode',
        city: 'adresses[0].city',
        country: 'adresses[0].country',
      },
    };

    this.handleInputValueChange = this.handleInputValueChange.bind(this);
    this.handleClientChange = this.handleClientChange.bind(this);
    this.selectTitleAndSet = this.selectTitleAndSet.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { values, setFieldValue } = this.props;
    if (
      values.is_prospect === 'Oui' ||
      values.is_prospect === 'YES' ||
      values.is_prospect === true
    ) {
      setFieldValue('is_prospect', true);
    } else {
      setFieldValue('is_prospect', false);
    }
  }

  handleInputValueChange(filter) {
    this.props.setFieldValue('name', filter);
    this.setState({ clientFilter: filter });
  }

  onSubmit(e) {
    try {
      const { handleSubmit: formikSubmit } = this.props;
      if (this.props.values.client) {
        const { billingAddress } = this.props.values.client;
        this.props.setFieldValue('adresses', [
          {
            address: billingAddress.address,
            city: billingAddress.city,
            zipcode: billingAddress.zipcode,
            country: billingAddress.country,
          },
        ]);
      }
      formikSubmit(e);
    } catch (error) {
      this.setState({ hasError: "Can't submit form !!!" });
    }
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  handleClientChange(e) {
    if (!e) {
      this.props.setValues({
        name: '',
        title: '',
        description: '',
        intracom_vat: '',
        address: '',
        addresses: [],
        zipcode: '',
        city: '',
        country: '',
        contact: '',
        email: '',
        siret: '',
        isCompany: true,
        is_prospect: false,
      });
    } else {
      this.props.setValues(e);
    }
  }

  selectTitleAndSet(title) {
    if (title) {
      this.props.setFieldValue('title', title.value);
    } else {
      // if the user clears the field we reset the value
      this.props.setFieldValue('title', '');
    }
  }

  _handleChange(event) {
    this.setState({ valueIsCompany: event.target.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.siret !== this.props.values.siret) {
      this.computeIntracom(this.state.vat);
    }
  }

  computeIntracom(fill) {
    const {
      values: { siret },
      setFieldValue,
    } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (fill && !isNaN(siret) && siret.length === 14) {
      const siren = siret.slice(0, 9);
      setFieldValue(
        'intracom_vat',
        `FR ${(3 * (siren % 97) + 12) % 97}${siren}`
      );
    } else if (!fill) {
      setFieldValue('intracom_vat', '');
    }
  }

  displayTitleInCompanyMode() {
    const { t, values, errors, touched } = this.props;
    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];

    return (
      <Grid item xs={4}>
        <SelectAutoComplete
          name="title"
          label={t('client.title')}
          onChange={this.selectTitleAndSet}
          getOptionLabel={(option) => option.label}
          values={chooseTitles}
          valueSelected={find(
            chooseTitles,
            (elem) => elem.value === values.title
          )}
          showError={errors.unit && touched.unit}
          error={errors.unit}
        />
      </Grid>
    );
  }

  displayName() {
    const { t, values, setFieldValue, fetchInfoGreffe, errors, touched } =
      this.props;

    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];

    if (values.isCompany) {
      return (
        <AppCompanySearch
          setFieldValue={setFieldValue}
          fetchInfoGreffe={fetchInfoGreffe}
          t={t}
          values={values}
          isClient
        />
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SelectAutoComplete
            required
            mandatory
            name="title"
            label={t('client.title')}
            onChange={this.selectTitleAndSet}
            getOptionLabel={(option) => option.label}
            values={chooseTitles}
            valueSelected={find(
              chooseTitles,
              (elem) => elem.value === values.title
            )}
            showError={errors.unit && touched.unit}
            error={errors.unit}
          />
        </Grid>

        <Grid item xs={9}>
          <TextField
            required
            label={t('settings_section.clients.form_label_value')}
            placeholder={t('settings_section.clients.form_label_value')}
            name="name"
            onChange={(e) => {
              capitalizeForm(e, setFieldValue);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  handleClickProSpect() {
    const { values, setFieldValue } = this.props;
    let isProspect = true;
    if (
      values.is_prospect === true ||
      values.is_prospect === 'Oui' ||
      values.is_prospect === 'YES'
    ) {
      isProspect = false;
    }
    setFieldValue('is_prospect', isProspect);
  }

  handleCheckedProspect() {
    const { values } = this.props;
    if (
      values.is_prospect === 'Oui' ||
      values.is_prospect === true ||
      values.is_prospect === 'YES'
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { t, errors, values, closeDialog, setFieldValue, isSubmitting } =
      this.props;

    return (
      <div className="section p-10">
        {this.renderLoading(isSubmitting)}
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {errors && errors.internalError && (
                <div className="row">
                  <div className="input-field col s12">
                    <span className="helper-text">{errors.internalError}</span>
                  </div>
                </div>
              )}

              {this.state.hasError && (
                <div className="row">
                  <div className="input-field col s12">
                    <span className="helper-text">{this.state.hasError}</span>
                  </div>
                </div>
              )}

              {values.account && (
                <div className="row">Client number: {values.account}</div>
              )}
            </Grid>

            {/* row with radio buttons for company or individual */}
            <Grid item xs={12}>
              <AppRadioGroup
                color="secondary"
                name="isCompany"
                disabled={isSubmitting}
                row
                valueSelected={values.isCompany ? 'isCompany' : 'isNotCompany'}
                onChange={(e) => {
                  if (e.target.value === 'isCompany') {
                    setFieldValue('siret', this.state.siret);
                    setFieldValue('isCompany', true);
                    setFieldValue('intracom_vat', this.state.intracom_vat);
                  } else {
                    setFieldValue('isCompany', false);
                    setFieldValue('siret', '');
                    setFieldValue('intracom_vat', '');
                  }
                }}
              >
                <AppRadioButton
                  value="isCompany"
                  text={t('client.type.company')}
                  key="isCompany"
                />
                <div style={{ padding: '10px' }} />
                <AppRadioButton
                  value="isNotCompany"
                  text={t('client.type.person')}
                  key="isNotCompany"
                />
              </AppRadioGroup>
            </Grid>

            {/* row with either company search or a Title/Firstname/Lastname */}
            <Grid item xs={12}>
              {this.displayName()}
            </Grid>

            {/* row with description and checkbox */}
            <Grid item xs={9}>
              <TextField
                name="description"
                label={t('settings_section.clients.form_label_description')}
                placeholder={t(
                  'settings_section.clients.form_label_description'
                )}
                color="secondary"
                id="description"
              />
            </Grid>
            <Grid container item xs={3} alignItems="center">
              <AppCheckbox
                onClick={() => this.handleClickProSpect()}
                disabled={isSubmitting}
                checked={() => this.handleCheckedProspect()}
                labelText={t('settings_section.clients.form_is_prospect')}
                name="is_prospect"
                id="is_prospect"
              />
            </Grid>

            {/* row with address */}
            <Grid item xs={12}>
              <TextField
                required
                name="address"
                fullWidth
                label={t('settings_section.clients.form_label_address')}
                placeholder={t('settings_section.clients.form_label_address')}
                color="secondary"
                id="address"
              />
            </Grid>

            {/* row with zipcode, city and country. On mobile we keep zipcode and city on 50% width. On desktop they all sit in 1 row */}
            <Grid item xs={6} sm={4}>
              <TextField
                setWidthManualy="30%"
                required
                name="zipcode"
                label={t('settings_section.clients.form_label_zip')}
                placeholder={t('settings_section.clients.form_label_zip')}
                color="secondary"
                id="zipcode"
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                setWidthManualy="32%"
                required
                name="city"
                label={t('settings_section.clients.form_label_city')}
                placeholder={t('settings_section.clients.form_label_city')}
                color="secondary"
                value={values.city}
                id="city"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectAutoComplete
                required
                mandatory
                t={t}
                name="country"
                color="secondary"
                setWidthManualy="30%"
                placeholder={t('settings_section.clients.form_label_country')}
                label={t('settings_section.clients.form_label_country')}
                getOptionLabel={(elem) => {
                  if (!elem || (elem && isObject(elem) && !elem.alpha2)) {
                    return "Erreur d'initialisation";
                  }

                  return `${elem.name_fr} (${elem.alpha2})`;

                  // const item = countryList.find((i) => i.alpha2 === elem);

                  // if (item) {
                  //   return `${item.alpha2} - ${item.name_fr}`;
                  // }

                  // return elem;
                }}
                values={map(countryList, (ac) => ac)}
                valueSelected={
                  find(countryList, (elem) => elem.alpha2 === values.country) ||
                  find(countryList, (elem) => elem.alpha2 === 'FR')
                }
                onChange={(country) => {
                  this.props.setFieldValue('country', country.alpha2 || 'FR');
                }}
              />
            </Grid>

            {values.isCompany && this.displayTitleInCompanyMode()}

            {/* row with contact name (only for Company) */}
            {values.isCompany && (
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  label={t('settings_section.clients.form_label_contact')}
                  placeholder={t('settings_section.clients.form_label_contact')}
                  color="secondary"
                  name="contact"
                  onChange={(e) => {
                    capitalizeForm(e, setFieldValue);
                  }}
                />
              </Grid>
            )}

            {/* row with phone number and email */}
            <Grid item xs={12} sm={4}>
              <TextField
                setWidthManualy="32%"
                label={t('settings_section.clients.form_label_phone')}
                placeholder={t('settings_section.clients.form_label_phone')}
                color="secondary"
                name="phone"
                onChange={(e) => {
                  if (PHONE_NUMBER_TEXT_REGEX.test(e.target.value))
                    capitalizeForm(e, setFieldValue);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                setWidthManualy="64%"
                label={t('settings_section.clients.form_label_email')}
                placeholder={t('settings_section.clients.form_label_email')}
                color="secondary"
                name="email"
                required
              />
            </Grid>

            {/* row with IBAN and BIC */}
            <Grid item xs={12} sm={7}>
              <TextField
                setWidthManualy="64%"
                label={t('invoices.IBAN')}
                placeholder={t('invoices.IBAN')}
                color="secondary"
                name="iban"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                setWidthManualy="32%"
                label={t('invoices.BIC')}
                placeholder={t('invoices.BIC')}
                color="secondary"
                name="bic"
              />
            </Grid>

            {values.isCompany && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label={t('settings_section.clients.form_label_siret')}
                    placeholder={t('settings_section.clients.form_label_siret')}
                    color="secondary"
                    name="siret"
                    required={values?.country === 'FR'}
                  />
                </Grid>

                {/* row with VAT checkbox, and if checked, shows the field to enter the VAT Intracom number */}
                <Grid item container xs={12} alignItems="center">
                  <Grid item xs={4}>
                    <AppCheckbox
                      onClick={(e) => {
                        this.setState({ vat: e.target.checked });
                        this.computeIntracom(e.target.checked);
                      }}
                      disabled={isSubmitting}
                      checked={this.state.vat}
                      labelText={t('client.vat')}
                      name="vat"
                      id="vat"
                    />
                  </Grid>

                  <Grid item xs={8}>
                    {this.state.vat && (
                      <TextField
                        label={t('settings_section.clients.form_label_vat')}
                        placeholder={t(
                          'settings_section.clients.form_label_vat'
                        )}
                        color="secondary"
                        name="intracom_vat"
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            <ClientExtraAddress fieldNames={this.state.clientExtraFieldnames} />

            {/* row with buttons */}
            <Grid item container xs={12} justify="flex-end">
              <AppButton
                isDisabled={isSubmitting}
                color="transparentGrey"
                text={t('cancel')}
                onClick={closeDialog}
              />
              <AppButton
                isDisabled={isSubmitting}
                color="secondaryLight"
                text={this.props.buttonText || t('draft')}
                type="button"
                onClick={this.onSubmit}
                noBorder={true}
              />
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}
ClientsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  onCreated: PropTypes.func,
  t: PropTypes.func,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  clients: PropTypes.object,
  setValues: PropTypes.func,
  buttonText: PropTypes.string,
  fuzzy: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  fetchInfoGreffe: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  init: PropTypes.object,
  importClientError: PropTypes.bool,
};

const ClientsWithForm = withFormik({
  displayName: 'ClientsForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ClientsForm);

const ClientsFormWithStyles = withStyles(styles)(ClientsWithForm);

const TranslatedClientsFormWithStyles = withTranslation()(
  ClientsFormWithStyles
);

export default connect(state, dispatch)(TranslatedClientsFormWithStyles);
