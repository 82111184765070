import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import AppButton from 'components/AppButton';
import AppDialog from 'components/AppDialog';

import { useDialog } from 'hooks/dialogHooks';
import { isExistInVatrates } from 'helpers/VATRateHelpers';
import { useSelector } from 'react-redux';
import { filteredTree } from 'helpers/CategoryHelpers';

const SendButton = ({ separatorStyle }) => {
  const { showDialog: showApproveDialog, toggleDialog: toggleApprovalDialog } =
    useDialog();

  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue, handleSubmit, isValid } =
    useFormikContext();
  const fullVatRates = useSelector((state) => state.fullVatRates);
  const tree = useSelector((state) => state.categories.tree || []);
  const categories = useMemo(() => filteredTree(tree, t), [tree]);

  const existInvalidVatrates = () => {
    const { amounts } = values;
    const invalid = amounts.some(
      (amount) => !isExistInVatrates(amount, categories, fullVatRates)
    );
    return invalid;
  };

  const { state } = values;

  async function send() {
    // toggleApprovalDialog();
    if (existInvalidVatrates()) {
      return;
    }
    if (isValid) {
      setFieldValue('action', 'upsert', false);
      setFieldValue('state', 'validated', false);
    }
    handleSubmit();
  }

  function renderDialog() {
    if (showApproveDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleApprovalDialog}
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          onConfirm={send}
          contentText={t('invoice.confirm.create')}
          color="secondary"
        />
      );
    }

    return null;
  }

  if (state === 'paid' || state === 'validated') {
    return null;
  }

  return (
    <Fragment>
      {renderDialog()}
      <AppButton
        fullWidth
        isDisabled={isSubmitting}
        text={t(`save`)}
        onClick={() => {
          send();
        }}
      />
      <div className={separatorStyle} />
    </Fragment>
  );
};

SendButton.propTypes = {
  separatorStyle: PropTypes.string,
};

export default SendButton;
