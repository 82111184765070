import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { useFormikContext, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Textfield from '../../FormElements/textField';
import CurrencyTextField from '../../FormElements/CurrencyTextField';
import UploadFile from '../../UploadFile/formWrapper';
import DatePicker from '../../FormElementsDesigned/DatePicker';
import VATLineGroup from '../../VATLineGroup';
import AppCheckbox from '../../AppCheckbox';
import ExpenseState from '../../states/ExpenseState';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '10px 0',
  },
}));

const FullForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values } = useFormikContext();
  const vatRates = useSelector((state) => state.vatRates);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <UploadFile readOnly={true} isPreviewFile />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {values.error ? <div style={{ color: 'red' }}>{values.error}</div> : ''}
        <Textfield
          type="text"
          name={'categoryDisplay'}
          label={t('expenses.expense.category')}
          disabled={true}
        />
        <Field
          type="date"
          name="date"
          label="Date"
          disabled={true}
          component={DatePicker}
          keyboard={true}
        />
        <Textfield
          type="text"
          name={'payment_typeDisplay'}
          label={t('expenses.expense.form_paymenttype')}
          disabled={true}
        />
        <Textfield
          type="text"
          name={'expense_nb'}
          label={t('invoices.list.nb')}
          disabled={true}
        />

        <Divider className={classes.divider} />
        <Textfield
          type="text"
          name={'billNumber'}
          label={t('expenses.invoices.invoice_nb_helper')}
          disabled={true}
        />
        <Textfield
          type="text"
          name={'reference'}
          label={t('expenses.invoices.reference_helper')}
          disabled={true}
        />
        <Textfield
          type="text"
          name={'orderNumber'}
          label={t('invoices.header.bon_de_commande')}
          disabled={true}
        />

        <AppCheckbox
          checked={values.is_intern}
          labelText={t('expenses.expense.form_isIntern')}
          name={`is_intern`}
          id={`is_intern`}
          onClick={() => {}}
          disabled={true}
        />
        <AppCheckbox
          checked={values.is_billable}
          labelText={t('expenses.expense.form_isBbillable')}
          name={`is_billable`}
          id={`is_billable`}
          onClick={() => {}}
          disabled={true}
        />

        <Divider className={classes.divider} />
        <ExpenseState
          state={t(`expenses.expense.state.${values.displayState}`)}
        />
        {values.displayState === '' ? null : <br></br>}
        <Field
          type="text"
          name="validated_at"
          label={t('expenses.expense.validated_at')}
          disabled={true}
          component={DatePicker}
          keyboard={true}
        />
        <Field
          type="text"
          name={`due_date`}
          label={t('expenses.expense.form_due_date')}
          disabled={true}
          component={DatePicker}
          keyboard={true}
        />

        {/* provider */}
        <Textfield
          type="text"
          name={'providerDisplay'}
          label={t('expenses.list.provider')}
          disabled={true}
        />
        {/* form_activity + client + project */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Textfield
              type="text"
              name={'internal_activityDisplay'}
              label={t('expenses.expense.form_activity')}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Textfield
              type="text"
              name={'clientDisplay'}
              label={t('expenses.expense.form_client')}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Textfield
              type="text"
              name={'projectDisplay'}
              label={t('expenses.expense.form_project')}
              disabled={true}
            />
          </Grid>
          {values.travel_from && values.travel_from.length > 0 ? (
            <Grid item xs={11} sm={4}>
              <Textfield
                type="text"
                color="secondary"
                name={`travel_from`}
                id={`travel_from`}
                label={t('expenses.expense.form_expensefrom')}
                placeholder={t('expenses.expense.form_expensefrom')}
                disabled={true}
              />
            </Grid>
          ) : null}
          {values.travel_from && values.travel_from.length > 0 ? (
            <Grid item xs={11} sm={4}>
              <Textfield
                type="text"
                color="secondary"
                name={`travel_to`}
                placeholder={t('expenses.expense.form_expenseto')}
                label={t('expenses.expense.form_expenseto')}
                disabled={true}
              />
            </Grid>
          ) : null}
        </Grid>

        <Textfield
          type="text"
          name={'description'}
          label={t('expenses.expense.description')}
          disabled={true}
        />
        <VATLineGroup
          readOnly={true}
          numberOfVatLine={values.numberOfVatLine}
          vatRates={vatRates}
          isPurchaseForm
        />
        <CurrencyTextField
          type="text"
          name={'duePayableAmount'}
          label={t('invoices.due_payable_amount')}
          disabled={true}
        />
      </Grid>
    </Grid>
  );
};

FullForm.propTypes = {
  useless: PropTypes.bool,
};

export default FullForm;
