import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMediaQuery, Icon, Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appSideButton';
import '../../assets/css/icons.css';

const AppSidebarButton = ({
  text,
  disabled,
  type,
  classes,
  onClick,
  pageDisplayed,
  iconMUI,
  onlyIcon = false,
}) => {
  const { isCollapse } = useSelector((state) => state.resizeScreen);
  const smallscreen = !useMediaQuery('(min-width:1024px)');
  const btnClasses = classNames({
    [classes.button]: true,
    [classes.disabled]: disabled,
    [classes.pageDisplayed]: pageDisplayed,
  });

  return (
    <Tooltip
      title={isCollapse ? text.toUpperCase() : ''}
      placement="right-start"
    >
      <button
        type={type}
        className={btnClasses}
        onClick={onClick}
        disabled={disabled}
      >
        {smallscreen || isCollapse || onlyIcon ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Icon component={iconMUI} fontSize="medium" />
          </div>
        ) : (
          <p className={classes.buttonText}>{text}</p>
        )}
      </button>
    </Tooltip>
  );
};

AppSidebarButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  pageDisplayed: PropTypes.bool,
  type: PropTypes.string,
  iconName: PropTypes.string,
  iconMUI: PropTypes.object,
  onlyIcon: PropTypes.bool,
};
AppSidebarButton.defaultProps = {
  disabled: false,
  onClick: null,
  iconName: '',
  pageDisplayed: false,
};

export default withStyles(style)(AppSidebarButton);
