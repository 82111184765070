import * as yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA, REGEX } from 'constants/form';
import { FRANCE_COUNTRY_CODE } from 'config/Constants';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape(
    {
      name: yup.string().required(t('forms.mandatory_field')), //
      title: yup.string().when('isCompany', {
        is: false,
        then: yup.string().required(t('forms.mandatory_field')),
        otherwise: yup.string(),
      }),
      description: yup.string(),
      phone: yup
        .string()
        .min(10, t('signup.company.valid_phone_number'))
        .matches(
          PHONE_NUMBER_VALIDATE_SCHEMA,
          t('signup.company.valid_phone_number')
        ),
      intracom_vat: yup.string(),
      address: yup.string().required(t('forms.mandatory_field')),
      clientHasDifferentBillingAddress: yup.boolean(),
      adresses: yup
        .array()
        .of(
          yup.object().shape({
            address: yup.string().required(t('forms.mandatory_field')),
            city: yup.string().required(t('forms.mandatory_field')),
            country: yup.string().required(t('forms.mandatory_field')),
            zipcode: yup.string().required(t('forms.mandatory_field')),
          })
        )
        .when('clientHasDifferentBillingAddress', {
          is: true,
          then: (adresses) => {
            adresses.min(1);
          },
          otherwise: (adresses) => {
            adresses.ensure().cast(null);
          },
        }),
      zipcode: yup.string().required(t('forms.mandatory_field')),
      city: yup.string().required(t('forms.mandatory_field')),
      country: yup.string(),
      contact: yup.string().when('isCompany', {
        is: true,
        then: yup.string().required(t('forms.mandatory_field')),
      }),
      email: yup
        .string()
        .email(t('signup.company.email_valid_mandatory'))
        .required(t('forms.mandatory_field')),
      isCompany: yup.boolean(),
      is_prospect: yup.boolean(),
      iban: yup
        .string()
        .max(34, t('settings_section.clients.iban_validation'))
        .min(23, t('forms.iban_minimum'))
        .matches(REGEX.IBAN, t('forms.iban_invalid')),
      bic: yup.string().max(11, t('settings_section.clients.bic_validation')),
      siret: yup.string().when(['isCompany', 'country'], {
        is: (isCompany, country) =>
          isCompany && country === FRANCE_COUNTRY_CODE,
        then: yup
          .string()
          .matches(
            /^[0-9]{14}$/,
            t('settings_section.clients.siret_validation')
          )
          .required(t('forms.mandatory_field')),
        otherwise: yup.string(),
      }),
    },
    [['clientHasDifferentBillingAddress', 'adresses']]
  );
