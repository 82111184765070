const precise = (value, decimalsNumber = 2) => {
  return parseFloat(value).toFixed(decimalsNumber);
};

// This function is comming from Vassilis Pallas answer on StackOverflow
// https://stackoverflow.com/questions/12820312/equivalent-to-php-function-number-format-in-jquery-javascript/45709108#45709108

const format = (number, decimals, dec, thousands) => {
  // console.log('number', number, 'typeof', typeof number);
  if (number === null || number === undefined) {
    // throw new TypeError('number is not valid');
    return '';
  }
  let value = number;

  let decSep = ',';
  if (dec) {
    decSep = dec;
  }

  let thousandsSep = ' ';
  if (thousands) {
    thousandsSep = thousands;
  }

  if (typeof value === 'string') {
    value = value.replace(new RegExp(thousandsSep, 'g'), '') || '';
    const splitComma = value.split(',');
    if (splitComma.length === 2) {
      // TODO check on index 1 if '.' which means we can have this valie '1,200.99'
      value = value.replace(decSep, '.');
    }
  }
  // console.log(value);
  if (value === '') {
    value = 0;
  }

  if (!Number.isFinite(Number(value))) {
    // throw new TypeError('number is not valid');
    return '';
  }

  let decimalsNumber;
  if (!decimals && value) {
    const len = value.toString().split('.').length;
    decimalsNumber = len > 1 ? len : 0;
  } else {
    decimalsNumber = decimals;
  }

  let result = precise(value, decimalsNumber);

  result = result.replace('.', decSep);

  const splitNum = result.split(decSep);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
  result = splitNum.join(decSep);

  return result;
};

const getNumber = (formattedValue, decSep, thousandsSep) => {
  const strippedValue = formattedValue
    .replace(new RegExp(thousandsSep, 'g'), '')
    .replace(decSep, '.');

  const numericValue = parseFloat(strippedValue);

  return numericValue;
};

const n = (a, decimals = 2) => format(a, decimals.toString(), ',', ' ');
const p = (a) => format(a, '2', ',');

const formatNumberByNation = (value, language, decimals, thousands = ' ') =>
  format(value, decimals, language === 'fr' ? ',' : '.', thousands);

const NumberFormat = {
  format,
  n,
  p,
  precise,
  getNumber,
  formatNumberByNation,
};

export default NumberFormat;
