const ALL_COUNTRIES = require('../assets/data/countryList.json');
const DOMTOM = require('../assets/data/DomTom.json');

export const isInsideUE = (country) => {
  const countryFound = ALL_COUNTRIES.filter((c) => c.alpha2 === country)[0];
  if (countryFound && countryFound.UE === 'True') {
    return true;
  }
  return false;
};

export const isDomTom = (country) => {
  const countryFound = ALL_COUNTRIES.filter((c) => c.alpha2 === country)[0];
  if (countryFound && DOMTOM.includes(countryFound.alpha2)) {
    return true;
  }
  return false;
};
